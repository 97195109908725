<template>
  <div class="wrap">
    <div class="logoBox">
      <img src="../assets/logo.png" class="logo" alt="" />
      <p>密信</p>
    </div>
    <img src="../assets/app.png" class="page" alt="" />
    <div class="titleBox">
      <p>下载 密信</p>
      <p>让沟通更自由</p>
      <p>Make communication more free</p>
    </div>
    <div class="list">
      <div class="item">
        <div @click="clickAndroid">
          <img src="../assets/android.png" alt="" />
          <p>Android</p>
        </div>
      </div>
      <div class="item">
        <a @click="clickIos"><img src="../assets/ios.png" alt="" />
          <p>IOS签名版</p>
        </a>
      </div>
      <div class="item">
        <a @click="clickWindows"><img src="../assets/win.png" alt="" />
          <p>Windows</p>
        </a>
      </div>
      <div class="item">
        <a @click="clickIosStore"><img src="../assets/ios.png" alt="" />
          <p>IOS商店版</p>
        </a>
      </div>
    </div>
    <a :href="imservice">
      <img src="../assets/service.png" alt="" class="service" srcset="">
    </a>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      androidDownload: "",
      iosDownload: "",
      windowsDownload: "",
      iosDownloadAppStore: "",
      imservice: ""
    }
  },
  mounted() {
    axios.get('https://api.mixin6.com/api/base/config/get?key=androidDownload').then(res => {
      console.log(res)
      this.androidDownload = res.data.data.configValue
    })
    axios.get('https://api.mixin6.com/api/base/config/get?key=iosDownload').then(res => {
      console.log(res)
      this.iosDownload = res.data.data.configValue
    })
    axios.get('https://api.mixin6.com/api/base/config/get?key=windowsDownload').then(res => {
      console.log(res)
      this.windowsDownload = res.data.data.configValue
    })
    axios.get('https://api.mixin6.com/api/base/config/get?key=iosDownloadAppStore').then(res => {
      console.log(res)
      this.iosDownloadAppStore = res.data.data.configValue
    })
    axios.get('https://api.mixin6.com/api/base/config/get?key=imservice').then(res => {
      console.log(res)
      this.imservice = res.data.data.configValue
    })
  },
  methods: {
    clickIos() {

      window.location.href = this.iosDownload
    },
    clickAndroid() {
      console.log(this.androidDownload)
      window.location.href = this.androidDownload
    },
    clickWindows() {

      window.location.href = this.windowsDownload
    },
    clickIosStore() {

      window.location.href = this.iosDownloadAppStore
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
}

.list {
  display: flex;
  align-items: center;
  margin-top: 124px;
  margin-left: 100px;
}

.item {
  /* width: 120px;
  height: 120px; */
  width: 6vw;
  height: 6vw;
  background: #ffffff;
  box-shadow: 0px 8px 20px 0px rgba(24, 27, 41, 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 40px;
}

.item a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.item img {
  display: block;
  width: 2.6vw;
  height: 2.6vw;
  /* height: 50px; */
}

.item p {
  color: #38393d;
  font-size: 0.8vw;
  display: block;
  margin-top: 9px;
}

.titleBox {
  margin-left: 120px;
  margin-top: 120px;
}

.titleBox p:nth-of-type(1) {
  color: #fff;
  font-size: 2vw;
  font-weight: 900;
  display: block;
  margin-bottom: 20px;
}

.titleBox p:nth-of-type(2) {
  color: #fff;
  font-size: 3.3vw;
  font-weight: 600;
  margin-bottom: 10px;
  display: block;
}

.titleBox p:nth-of-type(3) {
  color: #fff;
  font-size: 1.5vw;
  display: block;
}

.wrap {
  background: url(../assets/bg.png) no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.service {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 60px;
}

.logoBox {
  display: flex;
  align-items: center;
  height: 100px;
  margin-top: 80px;
  margin-left: 120px;
}

.logo {
  width: 5.2vw;
  height: 5.2vw;

  display: block;
}

.logoBox p {
  font-size: 2vw;
  color: #fff;
  font-weight: 900;
  margin-left: 30px;
}

.page {
  /* width: 1138px; */
  /* height: 774px; */
  width: 60%;
  position: absolute;
  top: 8%;
  right: 40px;
}
</style>
