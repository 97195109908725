<template>
  <div id="app">
    <HelloWorld msg="Welcome to Your Vue.js App" v-if="state == 0" />
    <Index v-if="state == 1" />
    <Wx v-if="state == 2" />
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import Index from './view/index.vue'
import Wx from './components/weixin.vue'
export default {
  name: 'App',
  components: {
    HelloWorld,
    Index,
    Wx
  },
  data() {
    return {
      state: 0
    }
  },
  created() {
    document.title = "ImeChat"
    let userAgentInfo = navigator.userAgent;
    let Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
    let isPC = true;
    for (let i = 0; i < Agents.length; i++) {
      if (userAgentInfo.indexOf(Agents[i]) > 0) {
        isPC = false; break;
      }
    }

    if (isPC) {
      console.log('PC访问');
      this.state = 0
    } else {
      this.state = 1

      
      

    }

    // if (device.indexOf('iPad') > -1) {
    //   //  ipad 
    //   this.state = true
    // } else if (device.indexOf('Android') > -1 || device.indexOf('ios') > -1) {
    //   // 手机
    //   this.state = true
    // } else {
    //   // 电脑
    //   this.state = false
    // }


  },
  methods: {

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
}
</style>
