<template>
  <div class="wrap">
    <div class="logoBox">
      <img src="../assets/logo.png" class="logo" alt="" />
      <p>密信</p>
    </div>
    <div class="titleBox">
      <p>下载 密信</p>
      <p>让沟通更自由</p>
      <p>Make communication more free</p>
    </div>
    <img src="../assets/app.png" class="page" alt="" />

    <div class="list">
      <div class="item">
        <a @click="clickAndroid">
          <img src="../assets/android.png" alt="" />
          <p>Android</p>
        </a>
      </div>
      <div class="item">
        <a @click="clickIos">
          <img src="../assets/ios.png" alt="" />

          <p>IOS签名版</p>
        </a>
      </div>
      <div class="item">
        <a @click="clickWindows">
          <img src="../assets/win.png" alt="" />
          <p>Windows</p>
        </a>
      </div>
      <div class="item">
        <a @click="clickIosStore">
          <img src="../assets/ios.png" alt="" />
          <p>IOS商店版</p>
        </a>
      </div>
    </div>
    <a :href="imservice">
      <img src="../assets/service.png" alt="" class="service" srcset="">
    </a>
    <div class="wx" v-if="!state">
      <img src="../assets/share.png" alt="">

    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      androidDownload: "",
      iosDownload: "",
      windowsDownload: "",
      state: true,
      iosDownloadAppStore: "",
      imservice: ""
    }
  },
  mounted() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      this.state = false
      console.log('微信');
    } else {
      this.state = true
    }
    axios.get('https://api.mixin6.com/api/base/config/get?key=androidDownload').then(res => {
      console.log(res)
      this.androidDownload = res.data.data.configValue
    })
    axios.get('https://api.mixin6.com/api/base/config/get?key=iosDownload').then(res => {
      console.log(res)
      this.iosDownload = res.data.data.configValue
    })
    axios.get('https://api.mixin6.com/api/base/config/get?key=windowsDownload').then(res => {
      console.log(res)
      this.windowsDownload = res.data.data.configValue
    })
    axios.get('https://api.mixin6.com/api/base/config/get?key=iosDownloadAppStore').then(res => {
      console.log(res)
      this.iosDownloadAppStore = res.data.data.configValue
    })
    axios.get('https://api.mixin6.com/api/base/config/get?key=imservice').then(res => {
      console.log(res)
      this.imservice = res.data.data.configValue
    })
  },
  methods: {
    clickIos() {

      window.location.href = this.iosDownload
    },
    clickAndroid() {
      console.log(this.androidDownload)
      window.location.href = this.androidDownload
    },
    clickWindows() {

      window.location.href = this.windowsDownload
    },
    clickIosStore() {

      window.location.href = this.iosDownloadAppStore
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wx {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
}

.wx img {
  display: block;
  width: 40%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  right: 30px;
}

.service {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 30px;
}

a {
  text-decoration: none;
  display: block;
  text-align: center;
  width: 100%;
  height: 100%;
}

.list {
  margin-top: 19px;
}

.item {
  width: 160px;
  padding: 0 30px;
  height: 46px;
  background: #ffffff;
  box-shadow: 0px 8px 20px 0px rgba(24, 27, 41, 0.1);
  border-radius: 15px;
  margin: 0 auto 15px;
  display: flex;
  align-items: center;
}

.item a {
  display: flex;
  align-items: center;
}

.item img {
  display: block;
  width: 36px;
  height: 36px;
  margin-left: 15px;
}

.item p {
  color: #38393d;
  font-size: 16px;
  display: block;
  margin-left: 10px;
  font-weight: bold;
}

.titleBox {
  margin-top: 40px;
  text-align: center;
}

.titleBox p:nth-of-type(1) {
  color: #fff;
  font-size: 20px;
  font-weight: 900;
  display: block;
  margin-bottom: 5px;
}

.titleBox p:nth-of-type(2) {
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  display: block;
}

.titleBox p:nth-of-type(3) {
  color: #fff;
  font-size: 14px;
  display: block;
}

.wrap {
  background: url(../assets/bg2.png) no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.logoBox {
  display: flex;
  align-items: center;
  height: 56px;
  margin-top: 20px;
  margin-left: 20px;
  width: 170px;
}

.logo {
  width: 56px;
  height: 56px;

  display: block;
}

.logoBox p {
  font-size: 20px;
  color: #fff;
  font-weight: 900;
  margin-left: 15px;
}

.page {
  width: 331px;
  height: 251px;
  display: block;
  margin: 0 auto;
}
</style>
